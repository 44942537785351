import React from 'react';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import Typography from '@components/common/typography';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import Status from '@components/common/status';
import { useTranslation } from 'react-i18next';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiAccountService } from '@api';
import { enumTranslate, ETransactionStatusTranslate } from '@locales/index';
import { currencies } from '@const/currency';
import { getTransactionStatus, prettifyAmount } from '@utils/index';
import Skeleton from '@components/common/skeleton';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { ETransactionAction, ETransactionMethod, ETransactionStatus } from '@xeppt/xeppt-sdk/types';
import { useUserContext } from '@hooks/context/useUserContext';
import { useLocales } from '@hooks/helpers/useLocales';
import { useApiMutation } from '@hooks/api/useApiMutation';

const TransactionDetailsLayout = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation('transaction_details');
    const { id } = useParams<{ id: string }>();
    const { refetchAccount } = useUserContext();
    const { requestErrorLocale, requestSuccessLocale } = useLocales();

    const {
        data: transaction,
        isLoading,
        handleRequest
    } = useApiQuery({
        method: () => apiAccountService.getTransactionById(id as string)
    });

    const { handleRequest: handleApproveTransaction, isLoading: isApproveLoading } = useApiMutation(
        {
            method: () => {
                return fetch(`${process.env.REACT_APP_BACKEND_URL}/approve/${id}`);
            },
            onSuccess: () => {
                handleRequest();
                requestSuccessLocale('approve_transaction');
                refetchAccount();
            },
            onError: requestErrorLocale
        }
    );

    const { handleRequest: handleCancelTransaction, isLoading: isCancelLoading } = useApiMutation({
        method: () => {
            return fetch(`${process.env.REACT_APP_BACKEND_URL}/cancel/${id}`);
        },
        onSuccess: () => {
            handleRequest();
            requestSuccessLocale('cancel_transaction');
            refetchAccount();
        },
        onError: requestErrorLocale
    });

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.head}>
                <Button onClick={() => navigate(-1)} leftIcon="arrow_left">
                    {t('summary')}
                </Button>
            </div>
            {isLoading ? (
                <Skeleton className={styles.skeleton} />
            ) : (
                <div className={styles.content_wrapper}>
                    <Typography variant="body1" weight="bold">
                        {t('details')}
                    </Typography>
                    <div className={styles.content}>
                        <div className={styles.item}>
                            <Typography variant="body3">{t('transaction_id')}</Typography>
                            <Typography weight="bold" variant="body3">
                                {transaction?.id}
                            </Typography>
                        </div>
                        <div className={styles.item}>
                            <Typography variant="body3">{t('amount')}</Typography>
                            <Typography weight="bold" variant="body3">
                                {currencies[transaction?.currency as 'CAD']?.symbol}{' '}
                                {prettifyAmount(transaction?.amount?.toFixed(2) || '0')}{' '}
                                {transaction?.currency}
                            </Typography>
                        </div>
                        <div className={styles.item}>
                            <Typography variant="body3">{t('transaction_type')}</Typography>
                            <Typography weight="bold" variant="body3">
                                {transaction?.method &&
                                    enumTranslate[
                                        transaction.action === ETransactionAction.SEND_MONEY &&
                                        transaction.method === ETransactionMethod.EFT
                                            ? 'to_bank'
                                            : transaction.method
                                    ][i18n.language as 'en' | 'fr']}
                            </Typography>
                        </div>
                        <div className={styles.item}>
                            <Typography variant="body3">{t('description')}</Typography>
                            <Typography weight="bold" variant="body3">
                                {transaction?.description}
                            </Typography>
                        </div>
                        <div className={styles.item}>
                            <Typography variant="body3">{t('destination')}</Typography>
                            <Typography weight="bold" variant="body3">
                                {transaction?.details.destination}
                            </Typography>
                        </div>
                        <div className={styles.item}>
                            <Typography variant="body3">{t('date')}</Typography>
                            <Typography weight="bold" variant="body3">
                                {moment(transaction?.createdAt).format('DD/MM/YYYY HH:mm')}
                            </Typography>
                        </div>
                        {/*<div className={styles.item}>*/}
                        {/*    <Typography variant="body3">{t('fee')}</Typography>*/}
                        {/*    <Typography weight="bold" variant="body3">*/}
                        {/*        $ 1.00 CAD*/}
                        {/*    </Typography>*/}
                        {/*</div>*/}
                        <div className={styles.item}>
                            <Typography variant="body3">{t('status')}</Typography>
                            <Typography weight="bold" variant="body3">
                                <Status variant={getTransactionStatus(transaction?.status)}>
                                    {transaction?.status &&
                                        ETransactionStatusTranslate[transaction.status][
                                            i18n.language as 'en' | 'fr'
                                        ]}
                                </Status>
                            </Typography>
                        </div>
                        {transaction?.status === ETransactionStatus.PENDING && (
                            <div className={styles.item}>
                                <Typography variant="body3">Approve</Typography>
                                <Typography weight="bold" variant="body3">
                                    <Button
                                        className={styles.button}
                                        variant="primary"
                                        size="small"
                                        isLoading={isApproveLoading}
                                        onClick={handleApproveTransaction}>
                                        Approve transaction
                                    </Button>
                                </Typography>
                            </div>
                        )}
                        {(transaction?.method === ETransactionMethod.E_TRANSFER ||
                            transaction?.method === ETransactionMethod.EFT) &&
                            transaction?.status === ETransactionStatus.PENDING && (
                                <div className={styles.item}>
                                    <Typography variant="body3">Cancel</Typography>
                                    <Typography weight="bold" variant="body3">
                                        <Button
                                            className={styles.button}
                                            variant="primary"
                                            size="small"
                                            isLoading={isCancelLoading}
                                            onClick={handleCancelTransaction}>
                                            Cancel transaction
                                        </Button>
                                    </Typography>
                                </div>
                            )}
                    </div>
                </div>
            )}
        </motion.div>
    );
};

export default TransactionDetailsLayout;
