import React, { FC, useEffect } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import Select from '@components/common/select';
import DatePicker from '@components/date_picker';
import Button from '@components/common/button';
import Radio from '@components/common/radio';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { EPaymentFrequency } from '@xeppt/xeppt-sdk/types/billing';
import FormField from '@components/form_field';
import { ESchedulePaymentType } from '@enum';
import { useFormContext, useWatch } from 'react-hook-form';
import { getFrequencyList, getLastPaymentDate, getNumberOfPayments } from '@utils/billing';
import moment from 'moment';
import Checkbox from '@components/common/checkbox';
import { pageAnimation } from '@const/animation';
import { motion } from 'framer-motion';
import { getDisablePast } from '@utils/index';
import { useUserContext } from '@hooks/context/useUserContext';

interface IProps {
    onNextStep: () => void;
    onPrevStep: () => void;
    isLoading: boolean;
}

const PaymentDetails: FC<IProps> = ({ onNextStep, onPrevStep, isLoading }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'pay_bills.pay_bills.payment_details'
    });
    const { t: balanceLocale } = useTranslation('sections', {
        keyPrefix: 'e_transfer.send_money.info'
    });
    const { account } = useUserContext();
    const { submitLocale, labelLocale, validationLocale } = useLocales();
    const { control, setValue, formState } = useFormContext();
    const watchedFrequency = useWatch({ name: 'paymentFrequency', control });
    const watchedPaymentType = useWatch({ name: 'paymentType', control });
    const watchedPaymentsEndDate = useWatch({ name: 'paymentsEndDate', control });
    const watchedNextPaymentAt = useWatch({ name: 'nextPaymentAt', control });
    const watchedNumberOfPayments = useWatch({ name: 'numberOfPayments', control });
    const watchedIsInfinitePayment = useWatch({ name: 'isInfinitePayment', control });
    const watchedName = useWatch({ name: 'name', control });
    const watchedAlias = useWatch({ name: 'alias', control });
    const watchedAccount = useWatch({ name: 'accountNumber', control });

    useEffect(() => {
        const resultNumberOfPayments = getNumberOfPayments({
            frequency: watchedFrequency,
            nextPaymentDate: watchedNextPaymentAt,
            lastPaymentDate: watchedPaymentsEndDate
        });
        if (
            watchedNumberOfPayments !== resultNumberOfPayments &&
            watchedPaymentType === ESchedulePaymentType.PAYMENTS_DATE
        ) {
            setValue('numberOfPayments', resultNumberOfPayments);
        }
    }, [watchedPaymentsEndDate, watchedFrequency, watchedNextPaymentAt]);

    useEffect(() => {
        const resultLastPaymentDate = getLastPaymentDate({
            frequency: watchedFrequency,
            nextPaymentDate: watchedNextPaymentAt,
            numberOfPayments: watchedNumberOfPayments
        });
        if (
            moment(watchedPaymentsEndDate).format('YYYY-MM-DD') !==
                moment(resultLastPaymentDate).format('YYYY-MM-DD') &&
            watchedPaymentType === ESchedulePaymentType.NUMBER_OF_PAYMENTS
        ) {
            setValue('paymentsEndDate', resultLastPaymentDate);
        }
    }, [watchedNumberOfPayments, watchedFrequency, watchedNextPaymentAt]);

    return (
        <motion.div {...pageAnimation} className={styles.content}>
            <div className={styles.content}>
                <div className={styles.header} style={{ borderBottom: 'none' }}>
                    <Typography variant="h5">{t('title')}</Typography>
                    <Typography variant="h5">
                        {watchedName} - {watchedAccount} {watchedAlias ? `(${watchedAlias})` : ''}
                    </Typography>
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.form_row}>
                    <FormField
                        name="name"
                        renderComponent={(props) => (
                            <Input full readOnly label={labelLocale('payee_name')} {...props} />
                        )}
                    />
                </div>
                <div className={styles.form_row}>
                    <FormField
                        name="accountNumber"
                        renderComponent={(props) => (
                            <Input full readOnly label={labelLocale('account_number')} {...props} />
                        )}
                    />
                    <FormField
                        name="alias"
                        renderComponent={(props) => (
                            <Input full readOnly label={t('name_label_label')} {...props} />
                        )}
                    />
                </div>
                <div className={styles.row}>
                    <Typography variant="body3" className={styles.balance}>
                        {balanceLocale('current_balance')}: ${account?.balance?.toFixed(2)}
                    </Typography>
                </div>
                <div className={styles.form_row}>
                    <FormField
                        name="amount"
                        renderComponent={(props) => (
                            <Input
                                full
                                label={labelLocale('amount')}
                                prefix="CAD"
                                rightIcon="canadian"
                                onlyNumbers
                                hideErrorIcon
                                {...props}
                            />
                        )}
                        rules={{
                            required: { value: true, message: validationLocale('amount') },
                            validate: (amount: number) => {
                                if (
                                    watchedFrequency === EPaymentFrequency.ONCE &&
                                    moment(watchedNextPaymentAt).isSame(moment(), 'day')
                                ) {
                                    if (amount > (account?.balance || 0)) {
                                        return validationLocale('not_enaught_balance');
                                    }
                                }
                            }
                        }}
                    />
                    <FormField<string | number>
                        name="paymentFrequency"
                        renderComponent={(props) => (
                            <Select
                                full
                                label={labelLocale('frequency')}
                                {...props}
                                items={getFrequencyList(t)}
                            />
                        )}
                        rules={{
                            required: { value: true, message: validationLocale('frequency') }
                        }}
                    />
                </div>
                <div className={styles.form_row}>
                    <FormField
                        name="nextPaymentAt"
                        renderComponent={(props) => (
                            <DatePicker
                                label={t('next_payment')}
                                full
                                {...props}
                                minDate={getDisablePast()}
                            />
                        )}
                        rules={{
                            required: { value: true, message: validationLocale('next_payment') }
                        }}
                    />
                    {watchedFrequency !== EPaymentFrequency.ONCE && (
                        <FormField<boolean>
                            name="isInfinitePayment"
                            renderComponent={({ value, onChange }) => (
                                <div className={styles.row} style={{ width: '100%' }}>
                                    <Checkbox
                                        checked={value}
                                        onClick={() => {
                                            onChange(!value);
                                        }}
                                    />
                                    {t('infinite_payment')}
                                </div>
                            )}
                        />
                    )}
                </div>
                {watchedFrequency !== EPaymentFrequency.ONCE && !watchedIsInfinitePayment && (
                    <div className={styles.form_row}>
                        <div style={{ width: '100%' }}>
                            <FormField<ESchedulePaymentType>
                                name="paymentType"
                                renderComponent={({ value, onChange }) => (
                                    <div className={styles.date}>
                                        <div
                                            className={styles.row}
                                            onClick={() =>
                                                onChange(ESchedulePaymentType.PAYMENTS_DATE)
                                            }>
                                            <Radio
                                                small
                                                checked={
                                                    value === ESchedulePaymentType.PAYMENTS_DATE
                                                }
                                            />{' '}
                                            <Typography variant="body3">
                                                {t('ending_date')}
                                            </Typography>
                                        </div>
                                        <FormField
                                            name="paymentsEndDate"
                                            renderComponent={(props) => (
                                                <DatePicker
                                                    full
                                                    label={t('ending_date')}
                                                    disabled={
                                                        value !== ESchedulePaymentType.PAYMENTS_DATE
                                                    }
                                                    {...props}
                                                />
                                            )}
                                        />
                                    </div>
                                )}
                                rules={{ required: true }}
                            />
                        </div>
                        <div style={{ width: '100%' }}>
                            <FormField<ESchedulePaymentType>
                                name="paymentType"
                                renderComponent={({ value, onChange }) => (
                                    <div className={styles.date}>
                                        <div
                                            className={styles.row}
                                            onClick={() =>
                                                onChange(ESchedulePaymentType.NUMBER_OF_PAYMENTS)
                                            }>
                                            <Radio
                                                small
                                                checked={
                                                    value ===
                                                    ESchedulePaymentType.NUMBER_OF_PAYMENTS
                                                }
                                            />{' '}
                                            <Typography variant="body3">
                                                <span>{t('or')}</span> {t('end')}
                                            </Typography>
                                        </div>
                                        <FormField
                                            name="numberOfPayments"
                                            renderComponent={(props) => (
                                                <Input
                                                    disabled={
                                                        value !==
                                                        ESchedulePaymentType.NUMBER_OF_PAYMENTS
                                                    }
                                                    full
                                                    label={labelLocale('number_of_payments')}
                                                    onlyNumbers
                                                    {...props}
                                                />
                                            )}
                                        />
                                    </div>
                                )}
                                rules={{ required: true }}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.actions}>
                <Button
                    variant="primary"
                    size="normal"
                    onClick={onNextStep}
                    isLoading={isLoading}
                    disabled={!formState.isValid}>
                    {submitLocale('next')}
                </Button>
                <Button leftIcon="arrow_left" onClick={onPrevStep}>
                    {submitLocale('back')}
                </Button>
            </div>
        </motion.div>
    );
};

export default PaymentDetails;
