import React, { useState, useEffect } from 'react';
import Typography from '@components/common/typography';
import Link from '@components/common/link';
import Input from '@components/common/input';
import Button from '@components/common/button';
import { routes } from '@const/routes';
import { useForm } from '@hooks/helpers/useForm';
import FormField from '@components/form_field';
import { zodResolver } from '@hookform/resolvers/zod';
import { defaultForgotPassword } from '@const/default_form_data';
import { forgotPasswordValidation } from '@helpers/validation_objects/forgot_password';
import { FormProvider } from 'react-hook-form';
import styles from './styles.module.scss';
import { apiAuthService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { pageAnimation } from '@const/animation';
import { motion } from 'framer-motion';
import { useApiMutation } from '@hooks/api/useApiMutation';

const ForgotPasswordLayout = () => {
    const { t } = useTranslation('forgot_password');
    const { validationLocale, requestSuccessLocale, labelLocale, requestErrorLocale } =
        useLocales();
    const token = new URLSearchParams(window.location.search).get('token');
    const navigate = useNavigate();
    const [isSentEmail, setIsSentEmail] = useState(false);
    const formMethods = useForm({
        defaultValues: defaultForgotPassword,
        resolver: zodResolver(
            forgotPasswordValidation({
                currentStep: isSentEmail ? 1 : 0,
                messages: {
                    email: validationLocale('email'),
                    password: validationLocale('password'),
                    passwordConfirm: validationLocale('passwordConfirm')
                }
            })
        )
    });

    useEffect(() => {
        if (token) {
            setIsSentEmail(true);
        }
    }, [token]);

    const { handleRequest: handleResetPassword, isLoading: isResetLoading } = useApiMutation({
        method: () => {
            const { password, passwordConfirm } = formMethods.getValues();
            return apiAuthService.resetPassword(token as string, password, passwordConfirm);
        },
        onSuccess: () => {
            requestSuccessLocale('reset_password');
            navigate(routes.signin);
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleSendEmail, isLoading: isSendEmailLoading } = useApiMutation({
        method: () => {
            const { email } = formMethods.getValues();
            return apiAuthService.forgotPassword(email);
        },
        onSuccess: () => {
            requestSuccessLocale('forgot_password');
        },
        onError: requestErrorLocale
    });

    const onForgotPassword = () => {
        formMethods.trigger().then((isValid) => {
            if (isValid) {
                if (!isSentEmail) {
                    handleSendEmail(undefined);
                } else {
                    handleResetPassword(undefined);
                }
            }
        });
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography className={styles.title} variant="h3">
                {t('title')}
            </Typography>
            <Typography className={styles.description} variant="body2">
                {t('new')} <Link to={routes.signup}>{t('signup')}</Link> or{' '}
                <Link to={routes.signin}>Sign-in</Link>
            </Typography>
            <FormProvider {...formMethods}>
                <div className={styles.form}>
                    {!isSentEmail ? (
                        <FormField
                            name="email"
                            renderComponent={(props) => (
                                <Input {...props} full label={labelLocale('email')} name="email" />
                            )}
                        />
                    ) : (
                        <>
                            <FormField
                                name="password"
                                renderComponent={(props) => (
                                    <Input
                                        {...props}
                                        full
                                        type="password"
                                        name="password"
                                        label={labelLocale('password')}
                                    />
                                )}
                            />
                            <FormField
                                name="passwordConfirm"
                                renderComponent={(props) => (
                                    <Input
                                        {...props}
                                        full
                                        type="password"
                                        name="passwordConfirm"
                                        label={labelLocale('passwordConfirm')}
                                    />
                                )}
                            />
                        </>
                    )}
                </div>
            </FormProvider>
            <Button
                onClick={onForgotPassword}
                isLoading={isResetLoading || isSendEmailLoading}
                variant="primary"
                size="normal">
                {t('send')}
            </Button>
        </motion.div>
    );
};
export default ForgotPasswordLayout;
