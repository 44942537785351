import React, { FC } from 'react';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import useSignupContext from '@hooks/context/useSignupContext';
import { useFormContext } from 'react-hook-form';
import Phone from '@components/phone';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { apiRegistrationService } from '@api';
import { toast } from 'react-toastify';
import { TNewPhone } from '@xeppt/xeppt-sdk/types';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

interface IProps {
    onCodeSent: () => void;
}

const PhoneSection: FC<IProps> = ({ onCodeSent }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'signup.phone'
    });
    const { submitLocale, labelLocale, requestErrorLocale } = useLocales();
    const { prevStep } = useSignupContext();
    const { getValues, trigger } = useFormContext();

    const { handleRequest: handleValidatePhone, isLoading: isValidateLoading } = useApiMutation({
        method: (data: TNewPhone) => apiRegistrationService.validatePhone(data),
        onSuccess: () => {
            const { phoneCode, phoneNumber } = getValues();
            handleSentValidationCode({
                phoneCode,
                phoneNumber
            });
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleSentValidationCode, isLoading: isSendLoading } = useApiMutation({
        method: (data: TNewPhone) => apiRegistrationService.sendPhoneValidationCode(data),
        onSuccess: () => {
            onCodeSent();
            toast.success('Verification code was sent on your phone');
        },
        onError: requestErrorLocale
    });

    const onSubmit = () => {
        const { phoneCode, phoneNumber } = getValues();
        trigger().then((isValid) => {
            if (isValid) {
                handleValidatePhone({
                    phoneCode,
                    phoneNumber
                });
            }
        });
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography className={styles.title} variant="h3">
                123
                {t('title')}
            </Typography>
            <div className={styles.form}>
                <Phone label={labelLocale('enter_phone')} />
                <Typography style={{ marginTop: 20 }} fz={10}>
                    {t('description')}
                </Typography>
                <Button
                    onClick={onSubmit}
                    size="normal"
                    variant="primary"
                    isLoading={isSendLoading || isValidateLoading}>
                    {submitLocale('submit_code')}
                </Button>
                <Button className={styles.back} leftIcon="arrow_left" onClick={prevStep}>
                    {submitLocale('back')}
                </Button>
            </div>
        </motion.div>
    );
};

export default PhoneSection;
